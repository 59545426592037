import React, { Component } from "react"
import { connect } from "react-redux"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { withTranslation } from "react-i18next"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import FMCOrderHeader from "../../components/shared/FMCOrderHeader"
import frGrid from "../../locales/fr-ca/grid.json"
import enGrid from "../../locales/en/grid.json"
import * as agGridHelpers from "../../helpers/agGridHelpers"
import { Segment, Modal, Menu, Button, Grid, Input, Checkbox } from "semantic-ui-react"
import { AgGridReact } from "ag-grid-react"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import { FR_LOCALE } from "../../constants/users"
import CustomNoRowsOverlay from "../../components/shared/CustomNoRowsOverlay"
import { agGridColumnDefs, agGridRowData } from "../../constants/paymentActivity"
import {
  agGridColumnSubsciptionDefs,
  agGridSubscriptionRowData
} from "../../constants/subscriptionActivity"
import moment from "moment"
import "moment-timezone"
import { Date_Format } from "../../constants/years"
import { isFleetAdvise, isFleetMaintenanceHubCanada } from "../../helpers/affiliationHelpers"
import Select from "../../elements/Select"
import * as fleetelpers from "../../helpers/fleetHelpers"
import debounce from "debounce"
import PaymentActivityModal from "../../components/shared/PaymentActivityModal"
import SubscriptionActivityModal from "../../components/shared/SubscriptionActivityModal"
import { isFmcPath } from "../../helpers/pathHelpers"
import { isFMCUser } from "../../helpers/userRoleHelpers"
import { FLEET } from "../../constants/application"
import FMCTransactionsShimmer from "./FMCTransactionsShimmer"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"

class FMCTransactions extends Component {
  constructor(props) {
    super(props)
    const locationStateData = this.props.location.state || {}
    let startingDate = locationStateData.startingDate
      ? moment(locationStateData.startingDate, "YYYY-MM-DD HH:mm:ss Z").add(1, "day") // Shitty hack to account for UTC being off-one-day for the continental US
      : moment().subtract(1, "month")
    // sometimes shitty hack makes us go to day 2, subtract.
    if (startingDate.date() == 2) startingDate = startingDate.subtract(1, "day")

    this.state = {
      transactionsStartDate: startingDate.startOf("day"),
      transactionsEndDate: moment(startingDate).add(1, "month").endOf("day"),
      subscriptionStartDate: startingDate.startOf("day"),
      subscriptionEndDate: moment(startingDate).add(1, "month").endOf("day"),
      showPaymentAcitivityGrid: false,
      showSubscriptionAcitivityGrid: false,
      sizeToFit: false,
      sizeToFitSubscription: false
    }

    // this.debouncedOnFilterChange = debounce(this.onFilterChange, 300)
    this.debouncedOnSizeToFitChange = debounce(this.onSizeToFitChange, 100)
    this.debouncedOnSizeToFitChangeSubscription = debounce(this.onSizeToFitSubscriptionChange, 100)
  }

  componentDidMount() {
    const { isFleetMode, isPathFMC, customer_fleet_id } = this.props
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())

    if (isFleetMode && !isPathFMC && customer_fleet_id) {
      const {
        transactionsEndDate,
        transactionsStartDate,
        subscriptionEndDate,
        subscriptionStartDate
      } = this.state
      this.loadPaymentActivities(transactionsStartDate, transactionsEndDate, customer_fleet_id)
      this.loadSubscriptionActivities(subscriptionStartDate, subscriptionEndDate, customer_fleet_id)
    } else if (isPathFMC) {
      this.loadAllFleets()
      const {
        transactionsEndDate,
        transactionsStartDate,
        fleet_id,
        subscriptionEndDate,
        subscriptionStartDate
      } = this.state
      this.loadPaymentActivities(transactionsStartDate, transactionsEndDate, fleet_id)
      this.loadSubscriptionActivities(subscriptionStartDate, subscriptionEndDate, fleet_id)
    }
  }

  componentWillReceiveProps(newProps) {
    const { isFleetMode, isPathFMC, customer_fleet_id } = newProps
    if (!isPathFMC && customer_fleet_id != this.state.fleet_id) {
      const { transactionsEndDate, transactionsStartDate } = this.state
      this.loadPaymentActivities(transactionsStartDate, transactionsEndDate, customer_fleet_id)
      this.loadSubscriptionActivities(transactionsStartDate, transactionsEndDate, customer_fleet_id)
    }
  }

  loadAllFleets = () => {
    this.props.dispatch({
      type: "ALL_FLEETS_LOAD_SAGA",
      payload: {}
    })
  }

  loadPaymentActivities = (transactionsStartDate, transactionsEndDate, fleet_id) => {
    this.setState({ showPaymentAcitivityGrid: false })
    this.props.dispatch({
      type: "PAYMENT_ACTIVITIES_LOAD_SAGA",
      payload: {
        options: {
          transactionsStartDate: transactionsStartDate.format(Date_Format.YYYY_MM_DD),
          transactionsEndDate: transactionsEndDate.format(Date_Format.YYYY_MM_DD),
          fleet_id: fleet_id
        }
      },
      callback: () => this.afterRequestSuccess(fleet_id)
    })
  }

  loadSubscriptionActivities = (transactionsStartDate, transactionsEndDate, fleet_id) => {
    this.setState({ showSubscriptionAcitivityGrid: false })
    this.props.dispatch({
      type: "SUBSCRIPTION_ACTIVITIES_LOAD_SAGA",
      payload: {
        options: {
          transactionsStartDate: transactionsStartDate.format(Date_Format.YYYY_MM_DD),
          transactionsEndDate: transactionsEndDate.format(Date_Format.YYYY_MM_DD),
          fleet_id: fleet_id
        }
      },
      callback: this.afterSubscriptionRequestSuccess
    })
  }

  noRowsTemplate() {
    return `<span> ${this.props.t("noTransaction")} </span>`
  }

  afterRequestSuccess = (fleet_id) => {
    this.setState({ showPaymentAcitivityGrid: true })
    this.setState({ fleet_id: fleet_id })
  }

  afterSubscriptionRequestSuccess = () => {
    this.setState({ showSubscriptionAcitivityGrid: true })
  }

  handleChangeStartDate = (date) => {
    this.setState({ transactionsStartDate: date })
    const { transactionsEndDate, fleet_id } = this.state
    this.loadPaymentActivities(date, transactionsEndDate, fleet_id)
  }

  handleChangeSubscriptionStartDate = (date) => {
    this.setState({ subscriptionStartDate: date })
    const { subscriptionEndDate, fleet_id } = this.state
    this.loadSubscriptionActivities(date, subscriptionEndDate, fleet_id)
  }

  handleChangeEndDate = (date) => {
    this.setState({ transactionsEndDate: date })
    const { transactionsStartDate, fleet_id } = this.state
    this.loadPaymentActivities(transactionsStartDate, date, fleet_id)
  }

  handleChangeSubscriptionEndDate = (date) => {
    this.setState({ subscriptionEndDate: date })
    const { subscriptionStartDate, fleet_id } = this.state
    this.loadSubscriptionActivities(subscriptionStartDate, date, fleet_id)
  }

  defaultCellRenderer(cellParams) {
    return <div>{cellParams.value}</div>
  }

  downloadOrders = () => {
    const { transactionsEndDate, transactionsStartDate, fleet_id } = this.state
    this.props.dispatch({
      type: "DOWNLOAD_PAYMENT_ACTIVITIES_SAGA",
      payload: {
        options: {
          transactionsStartDate: transactionsStartDate.format(Date_Format.YYYY_MM_DD),
          transactionsEndDate: transactionsEndDate.format(Date_Format.YYYY_MM_DD),
          fleet_id: fleet_id
        }
      }
    })
  }

  downloadSubscriptedOrders = () => {
    const { subscriptionEndDate, subscriptionStartDate, fleet_id } = this.state
    this.props.dispatch({
      type: "DOWNLOAD_PAYMENT_SUBSCRIPTION_SAGA",
      payload: {
        options: {
          subscriptionStartDate: subscriptionStartDate.format(Date_Format.YYYY_MM_DD),
          subscriptionEndDate: subscriptionEndDate.format(Date_Format.YYYY_MM_DD),
          fleet_id: fleet_id
        }
      }
    })
  }

  onFleetChange = (fleet_id) => {
    const {
      transactionsEndDate,
      transactionsStartDate,
      subscriptionEndDate,
      subscriptionStartDate
    } = this.state
    this.loadPaymentActivities(transactionsStartDate, transactionsEndDate, fleet_id)
    this.loadSubscriptionActivities(subscriptionStartDate, subscriptionEndDate, fleet_id)
  }

  onFilterChange = (_, event) => {
    this.setState({ quickFilterText: event.value })
  }

  onGridReady = (params) => {
    this.api = params.api
    this.columnApi = params.columnApi
    if (this.state.sizeToFit) params.api.sizeColumnsToFit()
    window.addEventListener("resize", () => this.state.sizeToFit && this.api.sizeColumnsToFit())
  }

  onGridReadySubscription = (params) => {
    this.apiSubscription = params.api
    this.columnApiSubscription = params.columnApi
    if (this.state.sizeToFitSubscription) params.apiSubscription.sizeColumnsToFit()
    window.addEventListener(
      "resize",
      () => this.state.sizeToFitSubscription && this.apiSubscription.sizeColumnsToFit()
    )
  }

  onSizeToFitChange = (event, syntheticEvent) => {
    this.setState({ sizeToFit: syntheticEvent.checked })
    if (syntheticEvent.checked) this.api.sizeColumnsToFit()
    else this.redrawGrid()
  }

  onSizeToFitSubscriptionChange = (event, syntheticEvent) => {
    this.setState({ SizeToFitSubscription: syntheticEvent.checked })
    if (syntheticEvent.checked) this.apiSubscription.sizeColumnsToFit()
    else this.redrawGridSubscription()
  }

  redrawGrid() {
    this.setState({ showPaymentAcitivityGrid: false })
    setTimeout(() => this.setState({ showPaymentAcitivityGrid: true }), 500)
  }

  redrawGridSubscription() {
    this.setState({ showSubscriptionAcitivityGrid: false })
    setTimeout(() => this.setState({ showSubscriptionAcitivityGrid: true }), 500)
  }

  viewCellRenderer = (cellParams) => {
    const payment_activity_id = cellParams.value
    const { t } = this.props
    return (
      <PaymentActivityModal payment_activity_id={payment_activity_id}>
        <span className="link">{t("services:viewdetailsLabel")}</span>
      </PaymentActivityModal>
    )
  }

  viewPaymentMethodRenderer = (cellParams) => {
    const subscription_id = cellParams.value
    const { t } = this.props
    return (
      <SubscriptionActivityModal subscription_id={subscription_id}>
        <span className="link">{t("services:showPaymentMethodLabel")}</span>
      </SubscriptionActivityModal>
    )
  }

  render() {
    const { language, t, paymentActivities, fleets, subscriptionActivities, isPathFMC } = this.props
    const {
      transactionsEndDate,
      transactionsStartDate,
      fleet_id,
      showPaymentAcitivityGrid,
      showSubscriptionAcitivityGrid,
      subscriptionStartDate,
      subscriptionEndDate
    } = this.state

    return (
      <div>
        <ApplicationLayout>
          <span />
          <FMCOrderHeader header={t("fmcDashboard:transactionsLabel")} />
          <br />
          {isPathFMC ? (
            <Grid style={{ marginTop: "2px" }}>
              <Grid.Column width={7}>
                <Segment padded raised>
                  {fleets.length > 0 ? (
                    <Select
                      onChange={(event) => this.onFleetChange(event.target.value)}
                      options={fleetelpers.selectOptions(fleets)}
                      placeholder={t("services:selectFleetLabel")}
                      defaultValue={fleet_id}
                      value={fleet_id}
                    />
                  ) : (
                    ""
                  )}
                </Segment>
              </Grid.Column>
            </Grid>
          ) : (
            ""
          )}

          <Grid>
            <Grid.Column>
              <Segment.Group>
                <Segment className="menu-container">
                  <Menu pointing secondary stackable>
                    <Menu.Item position="left" className="border-unset">
                      <Checkbox
                        label={t("services:sizeToFitLabel")}
                        onChange={this.debouncedOnSizeToFitChange}
                        checked={this.state.sizeToFit}
                        className={isFleetAdvise() ? "size-to-fit" : ""}
                      />
                    </Menu.Item>

                    <Menu.Item position="right" className="skinny-menu-button">
                      <div className={"service-daterange"}>
                        <div className={"service-datepicker"}>
                          {t("services:fromLabel")}:
                          <DatePicker
                            selected={transactionsStartDate}
                            selectsStart
                            dateFormat={
                              isFleetMaintenanceHubCanada()
                                ? Date_Format.YYYY_MM_DD
                                : Date_Format.MM_DD_YYYY
                            }
                            pastEndDate={transactionsEndDate}
                            onChange={(date) => this.handleChangeStartDate(date)}
                          />
                        </div>
                        <div className={"service-datepicker"}>
                          {t("services:toLabel")}:
                          <DatePicker
                            selected={transactionsEndDate}
                            dateFormat={
                              isFleetMaintenanceHubCanada()
                                ? Date_Format.YYYY_MM_DD
                                : Date_Format.MM_DD_YYYY
                            }
                            selectsEnd
                            pastStartDate={transactionsStartDate}
                            onChange={(date) => this.handleChangeEndDate(date)}
                          />
                        </div>
                        <Button
                          icon="download"
                          disabled={paymentActivities && paymentActivities.length === 0}
                          content={t("services:downloadLabel")}
                          onClick={() => this.downloadOrders()}
                        />
                      </div>
                    </Menu.Item>
                  </Menu>
                </Segment>

                <Segment
                  className={"ag-theme-material tab-container"}
                  style={{
                    height: "350px"
                  }}
                >
                  {paymentActivities && showPaymentAcitivityGrid ? (
                    <AgGridReact
                      columnDefs={agGridColumnDefs(
                        this.defaultCellRenderer,
                        this.viewCellRenderer,
                        t,
                        paymentActivities
                      )}
                      defaultColDef={{
                        sortable: true,
                        filter: true,
                        headerComponentParams: {
                          template:
                            '<div class="ag-cell-label-container" role="presentation">' +
                            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                            "  </div>" +
                            "</div>"
                        }
                      }}
                      localeText={language === FR_LOCALE ? frGrid : enGrid}
                      rowData={agGridRowData(paymentActivities, language)}
                      rowSelection={"single"}
                      frameworkComponents={{
                        customNoRowsOverlay: CustomNoRowsOverlay
                      }}
                      onGridReady={this.onGridReady}
                      noRowsOverlayComponent={"customNoRowsOverlay"}
                      noRowsOverlayComponentParams={{
                        translationFile: "fmcDashboard",
                        label: "noTransaction"
                      }}
                      quickFilterText={this.state.quickFilterText}
                      cacheQuickFilter={true}
                      pagination={true}
                      paginationPageSize={4}
                      suppressMenuHide={true}
                      suppressMovableColumns={false}
                      suppressCellSelection={true}
                    ></AgGridReact>
                  ) : isFleetAdvise() ? (
                    <FMCTransactionsShimmer />
                  ) : (
                    <LoadingThrobber visible />
                  )}
                </Segment>
              </Segment.Group>
            </Grid.Column>
          </Grid>

          <Grid>
            <Grid.Column>
              <Segment.Group>
                <Segment className="menu-container">
                  <Menu pointing secondary stackable>
                    <Menu.Item position="left" className="border-unset">
                      <Checkbox
                        label={t("services:sizeToFitLabel")}
                        onChange={this.debouncedOnSizeToFitChangeSubscription}
                        checked={this.state.SizeToFitSubscription}
                        className={isFleetAdvise() ? "size-to-fit" : ""}
                      />
                    </Menu.Item>

                    <Menu.Item position="right" className="skinny-menu-button">
                      <div className={"service-daterange"}>
                        <div className={"service-datepicker"}>
                          {t("services:fromLabel")}:
                          <DatePicker
                            selected={subscriptionStartDate}
                            selectsStart
                            dateFormat={
                              isFleetMaintenanceHubCanada()
                                ? Date_Format.YYYY_MM_DD
                                : Date_Format.MM_DD_YYYY
                            }
                            pastEndDate={subscriptionEndDate}
                            onChange={(date) => this.handleChangeSubscriptionStartDate(date)}
                          />
                        </div>
                        <div className={"service-datepicker"}>
                          {t("services:toLabel")}:
                          <DatePicker
                            selected={subscriptionEndDate}
                            dateFormat={
                              isFleetMaintenanceHubCanada()
                                ? Date_Format.YYYY_MM_DD
                                : Date_Format.MM_DD_YYYY
                            }
                            selectsEnd
                            pastStartDate={subscriptionStartDate}
                            onChange={(date) => this.handleChangeSubscriptionEndDate(date)}
                          />
                        </div>
                        <Button
                          icon="download"
                          disabled={subscriptionActivities && subscriptionActivities.length === 0}
                          content={t("services:downloadLabel")}
                          onClick={() => this.downloadSubscriptedOrders()}
                        />
                      </div>
                    </Menu.Item>
                  </Menu>
                </Segment>
                <Segment
                  className={"ag-theme-material tab-container"}
                  style={{
                    height: "350px"
                  }}
                >
                  {showSubscriptionAcitivityGrid ? (
                    <AgGridReact
                      columnDefs={agGridColumnSubsciptionDefs(
                        this.defaultCellRenderer,
                        this.viewPaymentMethodRenderer,
                        t,
                        subscriptionActivities
                      )}
                      defaultColDef={{
                        sortable: true,
                        filter: true,
                        headerComponentParams: {
                          template:
                            '<div class="ag-cell-label-container" role="presentation">' +
                            '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
                            '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
                            '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
                            '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
                            '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
                            '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
                            '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
                            '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
                            "  </div>" +
                            "</div>"
                        }
                      }}
                      onGridReady={this.onGridReadySubscription}
                      localeText={language === FR_LOCALE ? frGrid : enGrid}
                      rowData={agGridSubscriptionRowData(subscriptionActivities, language)}
                      rowSelection={"single"}
                      frameworkComponents={{
                        customNoRowsOverlay: CustomNoRowsOverlay
                      }}
                      noRowsOverlayComponent={"customNoRowsOverlay"}
                      noRowsOverlayComponentParams={{
                        translationFile: "fmcDashboard",
                        label: "noTransaction"
                      }}
                      cacheQuickFilter={true}
                      pagination={true}
                      paginationPageSize={4}
                      suppressMenuHide={true}
                      suppressMovableColumns={false}
                      suppressCellSelection={true}
                    ></AgGridReact>
                  ) : isFleetAdvise() ? (
                    <FMCTransactionsShimmer />
                  ) : (
                    <LoadingThrobber visible />
                  )}
                </Segment>
              </Segment.Group>
            </Grid.Column>
          </Grid>
        </ApplicationLayout>
      </div>
    )
  }
}

const mapStateToProps = (state, props) => {
  const { users } = state
  const user = state.application.userSessionData || (users && users.currentUser)
  const isFMC = isFMCUser(user)
  const isPathFMC = isFmcPath()
  return {
    isPathFMC: isPathFMC,
    isFleetMode: state.application.mode === FLEET || (isFMC && !isPathFMC),
    paymentActivities: state.paymentActivities.paymentActivities,
    subscriptionActivities: state.paymentActivities.subscriptionActivities,
    language: users.currentUser ? users.currentUser.language : "",
    fleets: state.fleets.all_fleets,
    customer_fleet_id: user.fleet_id
  }
}

export default connect(mapStateToProps, null)(withTranslation("paymentActivity")(FMCTransactions))
