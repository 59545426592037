import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { routerPaths } from "../../constants/paths"
import { Redirect } from "react-router"
import { isFMCUser } from "../../helpers/userRoleHelpers"
import { isShell } from "../../helpers/affiliationHelpers"
import { isWex } from "../../helpers/userHelpers"

import { newFleetTemplate } from "../../constants/fleets"
import { navigationPaths } from "../../constants/paths"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import FleetForm from "../../components/fleets/FleetForm"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import { withTranslation } from "react-i18next"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"

class FleetNew extends Component {
  static propTypes = {
    fleet: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    fleetPaymentTypes: PropTypes.array.isRequired
  }

  constructor(props) {
    super(props)

    this.state = {
      alertMessage: "",
      alertType: "default",
      isSubmitting: false
    }
  }

  componentDidMount() {
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())

    this.props.dispatch({
      type: "FLEET_PAYMENT_TYPES_LOAD_SAGA",
      payload: {}
    })
    this.props.dispatch({
      type: "AFFILIATION_LOAD_SAGA",
      payload: {}
    })
  }

  afterRequest(status, data) {
    this.setState({ isSubmitting: false })

    if (status === "success") {
      this.onRequestSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  onSubmit(formData) {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "FLEET_CREATE_SAGA",
      payload: { formData: formData, t: this.props.t },
      callback: this.afterRequest.bind(this)
    })
  }

  onRequestFailure(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onRequestSuccess(data) {
    const { fleet } = this.props

    this.props.history.push({
      pathname: navigationPaths.fleetEdit(fleet.id),
      state: {
        alertMessage: data.alertMessage,
        alertType: data.alertType
      }
    })
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError } = this.props

    return !isLoading && !isLoadingError
  }

  renderContent() {
    const { fleetPaymentTypes, affiliation, currentUser } = this.props
    const { isSubmitting } = this.state

    if (currentUser && !isFMCUser(currentUser)) {
      // TODO: extract this work to the Router itself.
      return <Redirect to={routerPaths.dashboard} />
    } else if (currentUser && this.shouldRenderContent()) {
      return (
        <div className={"form_container"}>
          <FleetForm
            fleet={Object.assign({}, newFleetTemplate)}
            isSubmitting={isSubmitting}
            onDelete={() => true}
            onSubmit={this.onSubmit.bind(this)}
            paymentTypes={fleetPaymentTypes}
            canShareWexNumber={isWex(currentUser) && !isShell(affiliation)}
          />
        </div>
      )
    } else {
      return null
    }
  }

  render() {
    const { isLoading, isLoadingError, affiliation, currentUser } = this.props
    const { alertMessage, alertType } = this.state

    return (
      <ApplicationLayout>
        {alertMessage && <Alert message={alertMessage} type={alertType} />}

        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
      </ApplicationLayout>
    )
  }
} // class FleetNew

const mapStateToProps = (state) => ({
  fleet: state.fleets.fleet,
  fleetPaymentTypes: state.fleets.paymentTypes,
  currentUser: state.application.userSessionData || state.users.currentUser,
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  affiliation: state.affiliations.affiliation
})

export default connect(mapStateToProps)(withTranslation("fleetImpersonation")(FleetNew))
