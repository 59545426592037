import React, { Component } from "react"
import { BrowserRouter, Redirect, Route, Switch, withRouter } from "react-router-dom"
import { connect } from "react-redux"

import { routerPaths } from "./constants/paths"

import { userSignedIn } from "./helpers/sessionHelpers"
import { extractShellParams } from "./helpers/navigationHelpers"
import { isFleetMaintenanceHub } from "./helpers/affiliationHelpers"

import AccountShow from "./views/accounts/AccountShow"
import FleetEdit from "./views/fleets/FleetEdit"
import FleetNew from "./views/fleets/FleetNew"

import ActiveMaintenanceIndex from "./views/dashboard/ActiveMaintenanceIndex"
import Dashboard from "./views/dashboard/Dashboard"
import FMCDashboard from "./views/dashboard/FMCDashboard"
import FMCVehicles from "./views/accounts/FMCVehicles"
import FMCUsers from "./views/accounts/FMCUsers"
import ForgotPassword from "./views/sessions/ForgotPassword"
import PaymentsEdit from "./views/payments/PaymentsEdit"
import PaymentsNew from "./views/payments/PaymentsNew"
import ResetPassword from "./views/sessions/ResetPassword"
import SessionDestroy from "./views/sessions/SessionDestroy"
import SessionNew from "./views/sessions/SessionNew"
import SessionNewSignUp from "./views/sessions/SessionNewSignup"
import RecallIndex from "./views/dashboard/RecallIndex"
import ServiceIndex from "./views/services/ServiceIndex"
import ServiceRequestNew from "./views/service_requests/ServiceRequestNew"
import MaintenanceHistoryNew from "./views/maintenance_history/MaintenanceHistoryNew"
import ServiceRequestSchedule from "./views/service_requests/ServiceRequestSchedule"
import ShellNewUser from "./views/sessions/ShellNewUser"
import ShopIndex from "./views/shops/ShopIndex"
import UpcomingMaintenanceIndex from "./views/dashboard/UpcomingMaintenanceIndex"
import SettingsIndex from "./views/settings/SettingsIndex"
import VehicleIndex from "./views/vehicles/VehicleIndex"
import VehicleShow from "./views/vehicles/VehicleShow"
import ApprovalsShow from "./views/approvals/ApprovalsShow"
import UserPreferences from "./views/accounts/UserPreferences"
import MembershipPanel from "./views/accounts/MembershipPanel"
import PrivacyPolicyPage from "./views/sessions/PrivacyPolicyPage"
import FMCActiveOrders from "./views/accounts/FMCActiveOrders"
import FMCTransactions from "./views/accounts/FMCTransactions"
import TransferVehicle from "./views/accounts/TransferVehicles"
import FMCReporting from "./views/accounts/FMCReporting"
import store from "./actions/configureStore"
import { GET_EVENT_NAMES } from "./actions/sagas/segment"
import MagicLinkIndex from "./components/shared/MagicLink/MagicLinkIndex"
import { SETTINGS_MENU_ITEMS } from "./constants/menuItems"
import { IMPERSONATED_BY } from "./constants/users"
import DvirAdmin from "./views/dvir_admin/DvirAdmin"

class Appcues extends Component {
  componentDidUpdate(prevProps) {
    // NOTE: Appcues -- since we have a SPA we track page changes here
    // and wrap withRouter() so that we can track the /paths
    const {
      location: { pathname }
    } = this.props
    const { currentUser, fleet } = this.props

    const currentUserId = currentUser && currentUser.id
    const userFirstName = currentUser && currentUser.firstName
    const userEmail = currentUser && currentUser.email
    const fleetName = fleet && fleet.name
    const fleetNumOfVehicles = fleet && fleet.vehicles_total
    const previousLocation = prevProps.location.pathname
    const envVarString =
      process.env.REACT_APP_ENVIRONMENT == null ? "" : process.env.REACT_APP_ENVIRONMENT
    if (pathname !== previousLocation && currentUserId) {
      window.Appcues &&
        window.Appcues.identify(currentUserId + envVarString, {
          userFirstName: userFirstName,
          userEmail: userEmail,
          fleetName: fleetName,
          fleetNumOfVehicles: fleetNumOfVehicles
        })
    }
  }

  render() {
    return this.props.children
  }
}
const WrappedAppcues = withRouter(Appcues)

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return this.props.children
  }
}
const WrappedScrollToTop = withRouter(ScrollToTop)

class Router extends Component {
  componentDidMount() {
    this.props.dispatch({ type: GET_EVENT_NAMES })
  }
  // NOTE: ComponentToRender argument must be capitalized to interpolate as a tag name.
  preventDoubleSignIn(ComponentToRender, routeProps = {}) {
    const url = window.location.href
    const params =
      window.location.href.match(/key=([\w\d-.]+)?&?/) ||
      window.location.href.match(/error_message=([\w\d-]+)?&?/)
    const key = params && params[1]
    const path = window.location.pathname
    if (userSignedIn()) {
      return (
        <Redirect
          to={{ pathname: routerPaths.dashboard, state: { key: key, path: path, url: url } }}
        />
      )
    } else {
      return <ComponentToRender {...routeProps} />
    }
  }

  // NOTE: ComponentToRender argument must be capitalized to interpolate as a tag name.
  renderComponent(ComponentToRender, routeProps = {}) {
    return <ComponentToRender {...routeProps} />
  }

  renderShellComponent(ComponentToRender, routeProps = {}) {
    if (!isFleetMaintenanceHub()) {
      return <Redirect to={routerPaths.signIn} />
    }

    const url = new URL(window.location.href)

    if (url.search.length > 0) {
      // Extract params into the redux store, so we can clean the url path (including auth token)
      this.props.dispatch({
        type: "SHELL_NEW_USER_FORM",
        payload: { data: extractShellParams(new URLSearchParams(url.search)) }
      })

      return <Redirect to={routerPaths.shellCustomerEdit} />
    } else {
      return <ComponentToRender {...routeProps} />
    }
  }

  // NOTE: ComponentToRender argument must be capitalized to interpolate as a tag name.
  requireSignIn(ComponentToRender, routeProps = {}) {
    if (userSignedIn()) {
      return <ComponentToRender {...routeProps} />
    } else {
      const params = window.location.href.match(/key=([\w\d-.]+)?&?/)
      const impersonatedBy = window.location.href.match(/impersonated_by=([\w\d-.]+)?&?/)
      const fleetId = window.location.href.match(/fleet_id=([\w\d-.]+)?&?/)
      const key = params && params[1]
      const impersonatedUserId = impersonatedBy && impersonatedBy[1]
      if (impersonatedUserId) {
        localStorage.setItem(IMPERSONATED_BY, impersonatedUserId)
      }
      const path = window.location.pathname

      return (
        <Redirect
          to={{
            pathname: window.location.href.includes(routerPaths.magicLink)
              ? routerPaths.magicLink
              : routerPaths.signIn,
            state: { key: key, path: path, fleetId: fleetId && fleetId[1] }
          }}
        />
      )
    }
  }

  render() {
    const { user, fleet } = this.props

    return (
      <BrowserRouter>
        <WrappedAppcues currentUser={user} fleet={fleet}>
          <WrappedScrollToTop>
            <Switch>
              <Route
                exact
                path={routerPaths.root}
                render={(props) => this.requireSignIn(Dashboard, props)}
              />
              <Redirect
                exact
                from={routerPaths.root}
                to={userSignedIn() ? routerPaths.dashboard : routerPaths.signIn}
              />
              <Route
                exact
                path={routerPaths.fmcDashboard}
                render={(props) => this.requireSignIn(FMCDashboard, props)}
              />
              <Route
                exact
                path={routerPaths.fmcVehicles}
                render={(props) => this.requireSignIn(FMCVehicles, props)}
              />
              <Route
                exact
                path={routerPaths.fmcActiveOrders}
                render={(props) => this.requireSignIn(FMCActiveOrders, props)}
              />
              <Route
                exact
                path={routerPaths.fmcTransactions}
                render={(props) => this.requireSignIn(FMCTransactions, props)}
              />
              <Route
                exact
                path={routerPaths.fleetTransactions}
                render={(props) => this.requireSignIn(FMCTransactions, props)}
              />
              <Route
                exact
                path={routerPaths.transferVehicles}
                render={(props) => this.requireSignIn(TransferVehicle, props)}
              />
              <Route
                exact
                path={routerPaths.fmcReporting}
                render={(props) => this.requireSignIn(FMCReporting, props)}
              />
              <Route
                exact
                path={routerPaths.fleetReporting}
                render={(props) => this.requireSignIn(FMCReporting, props)}
              />
              <Route
                exact
                path={routerPaths.fmcUsers}
                render={(props) => this.requireSignIn(FMCUsers, props)}
              />
              <Route
                exact
                path={routerPaths.fleetEdit}
                render={(props) => this.requireSignIn(FleetEdit, props)}
              />
              <Route
                exact
                path={routerPaths.fleetNew}
                render={(props) => this.requireSignIn(FleetNew, props)}
              />
              <Route
                exact
                path={routerPaths.account}
                render={(props) => this.requireSignIn(AccountShow, props)}
              />
              <Route
                exact
                path={routerPaths.dashboard}
                render={(props) => this.requireSignIn(Dashboard, props)}
              />
              <Route
                exact
                path={routerPaths.dashboardActiveMaintenances}
                render={(props) => this.requireSignIn(ActiveMaintenanceIndex, props)}
              />
              <Route
                exact
                path={routerPaths.dashboardRecalls}
                render={(props) => this.requireSignIn(RecallIndex, props)}
              />
              <Route
                exact
                path={routerPaths.dashboardUpcomingMaintenances}
                render={(props) => this.requireSignIn(UpcomingMaintenanceIndex, props)}
              />
              <Route
                exact
                path={routerPaths.shellCustomerEdit}
                render={(props) => this.renderShellComponent(ShellNewUser, props)}
              />
              <Route
                exact
                path={routerPaths.forgotPassword}
                render={(props) => this.renderComponent(ForgotPassword, props)}
              />
              <Route
                exact
                path={routerPaths.paymentsEdit}
                render={(props) => this.requireSignIn(PaymentsEdit, props)}
              />
              <Route
                exact
                path={routerPaths.paymentsNew}
                render={(props) => this.requireSignIn(PaymentsNew, props)}
              />
              <Route
                exact
                path={routerPaths.resetPassword}
                render={(props) => this.renderComponent(ResetPassword, props)}
              />
              <Route
                exact
                path={routerPaths.serviceIndex}
                render={(props) => this.requireSignIn(ServiceIndex, props)}
              />
              <Route
                exact
                path={routerPaths.serviceRequestNew}
                render={(props) => this.requireSignIn(ServiceRequestNew, props)}
              />
              <Route
                exact
                path={routerPaths.MaintenanceHistoryNew}
                render={(props) => this.requireSignIn(MaintenanceHistoryNew, props)}
              />
              <Route
                exact
                path={routerPaths.maintenanceHistoryEdit}
                render={(props) => this.requireSignIn(MaintenanceHistoryNew, props)}
              />
              <Route
                exact
                path={routerPaths.serviceRequestSchedule}
                render={(props) => this.requireSignIn(ServiceRequestSchedule, props)}
              />
              <Route
                exact
                path={routerPaths.settingsIndex}
                render={(props) => this.requireSignIn(SettingsIndex, props)}
              />
              <Route
                exact
                path={routerPaths.reminders}
                render={(props) =>
                  this.requireSignIn(SettingsIndex, {
                    ...props,
                    activeTab: SETTINGS_MENU_ITEMS.REMINDERS
                  })
                }
              />
              <Route
                exact
                path={routerPaths.shopIndex}
                render={(props) => this.requireSignIn(ShopIndex, props)}
              />
              <Route
                exact
                path={routerPaths.signIn}
                render={(props) => this.preventDoubleSignIn(SessionNew, props)}
              />
              <Route
                exact
                path={routerPaths.signOut}
                render={(props) => this.renderComponent(SessionDestroy, props)}
              />
              <Route
                exact
                path={routerPaths.signUp}
                render={(props) => this.renderComponent(SessionNewSignUp, props)}
              />
              <Route
                exact
                path={routerPaths.vehicleIndex}
                render={(props) => this.requireSignIn(VehicleIndex, props)}
              />
              <Route
                exact
                path={routerPaths.vehicleShow}
                render={(props) => this.requireSignIn(VehicleShow, props)}
              />
              <Route
                exact
                path={routerPaths.vehicleShowMaintenanceHistory}
                render={(props) => this.requireSignIn(VehicleShow, props)}
              />
              <Route
                exact
                path={routerPaths.vehicleShowMaintenanceSchedule}
                render={(props) => this.requireSignIn(VehicleShow, props)}
              />
              <Route
                exact
                path={routerPaths.vehicleShowMaintenanceScheduleDetails}
                render={(props) => this.requireSignIn(VehicleShow, props)}
              />
              <Route
                exact
                path={routerPaths.vehicleShowMaintenanceScheduleHistory}
                render={(props) => this.requireSignIn(VehicleShow, props)}
              />
              <Route
                exact
                path={routerPaths.vehicleShowPolicy}
                render={(props) => this.requireSignIn(VehicleShow, props)}
              />
              <Route
                exact
                path={routerPaths.approvalsShow}
                render={(props) => this.requireSignIn(ApprovalsShow, props)}
              />
              <Route
                exact
                path={routerPaths.userPreferences}
                render={(props) => this.requireSignIn(UserPreferences, props)}
              />
              <Route
                exact
                path={routerPaths.membershipCard}
                render={(props) => this.requireSignIn(MembershipPanel, props)}
              />
              <Route
                exact
                path={routerPaths.magicLink}
                render={(props) => this.renderComponent(MagicLinkIndex, props)}
              />
              <Route exact path={routerPaths.privacyPolicy} component={PrivacyPolicyPage} />
              <Route
                exact
                path={routerPaths.dvirAdmin}
                render={(props) => this.requireSignIn(DvirAdmin, props)}
              />
            </Switch>
          </WrappedScrollToTop>
        </WrappedAppcues>
      </BrowserRouter>
    )
  }
} //class Router

const mapStateToProps = (state) => {
  const user = state.users && state.users.currentUser
  const fleet = state.fleets.fleet
  return {
    user: user || null,
    fleet: fleet || null
  }
}

export default connect(mapStateToProps)(Router)
