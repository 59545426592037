import moment from "moment"

const startYear = 1980
const numYears = 40
export const AMERICA = "Etc/GMT+5"
export const years = [...Array(numYears).keys()].map((i) => {
  const num = i + startYear
  return { label: String(num), value: num }
})

export const Date_Format = Object.freeze({
  YYYY_MM_DD: "YYYY-MM-DD",
  MMMM_YYYY: "MMMM YYYY",
  MM_DD_YYYY: "MM-DD-YYYY",
  M_D_YY: "M/D/YY",
  MM_DD_YY: "MM/DD/YYYY",
  HH_MM_A: "hh:mmA",
  MM_DD_YY_HMA: "MM/DD/YY, h:mmA",
  DEFAULT_UTC_DATE_TIME: "YYYY-MM-DD 00:00:00 UTC",
  YYYY_MM_01: "YYYY-MM-01",
  L: "L",
  MM_DD_YY_H_MM_A: "MM/DD/YY h:mm A",
  YYYY_MM_DD_TIME: "YYYY-MM-DD HH:mm:ss",
  DDDD: "dddd",
  DD_MMM_YYYY: "DD/MMM/YY",
  MM_DD_YY: "MM/DD/YY"
})

export function formatDate(date, format = Date_Format.L) {
  return date ? moment(date).format(format) : "-"
}

export function formatDateWithUTC(date, format = Date_Format.L) {
  return date ? moment.utc(date).format(format) : "-"
}

export const getBookingAppointmentDayWithoutISO = (appointmentDateTime) =>
  moment(appointmentDateTime, Date_Format.YYYY_MM_DD_TIME).format(Date_Format.DDDD)
