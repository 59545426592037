import React, { useState, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import Select from "../../elements/Select"
import ReactSVG from "react-svg"
import ReportDownload from "../../assets/images/report_download.svg"
import ReportFilter from "../../assets/images/report_filter.svg"
import ReportCopy from "../../assets/images/report_copy.svg"
import UpArrow from "../../assets/images/up_arrow.svg"
import MUIDataTable from "mui-datatables"
import store from "../../actions/configureStore"
import { connect } from "react-redux"
import { Icon, Pagination } from "semantic-ui-react"
import { transactionReportColumnDefs, convertDateToString } from "../../constants/reports"
import ReportSideModal from "./ReportSideModal"
import MiniLoader from "../../components/shared/MiniLoader"
import FMCOrderHeader from "../../components/shared/FMCOrderHeader"
import ReportHistoryModal from "./ReportHistoryModal"
import SaveReportModal from "../../components/shared/SaveReportModal"
import { formatCurrency } from "../../utilities/currency"
import ReactTooltip from "react-tooltip"
import { isEmpty } from "lodash"
import moment from "moment"
import { Date_Format } from "../../constants/years"
import CrossIcon from "../../assets/images/cross_icon.svg"
import { NOTIFICATION_TYPE, SUCCESS } from "../../constants/notification"
import CustomNotification from "../../components/shared/CustomNotification"
import { getFmcAndFleetId } from "../../actions/sagas/reportingSaga"
import CustomizeReportModal from "../../components/shared/CustomizeReportModal"
import SplitClient from "../../components/config/SplitClient"

const FMCReporting = ({
  transactionDataRecords,
  totalPages,
  totalEntries,
  reportSummaryData,
  reportFilters,
  loadingTransactionRecords,
  loadingFilterRecords,
  fleetsNameById,
  shopsNameById,
  currentUser
}) => {
  const { t } = useTranslation("reports")

  const [reportingType, setReportingType] = useState(t("shopOrder"))
  const [filters, setFilters] = useState({})
  const [page, setPage] = useState(1)
  const [modalOpen, setModalOpen] = useState(false)
  const [openConfigureModal, setOpenConfigureModal] = useState(false)
  const [openSavedHistoryModal, setOpenSavedHistoryModal] = useState(false)
  const [filter, setFilter] = useState({})
  const [transactionDataSource, setTransactionDataSource] = useState([])
  const [visibleFilterTags, setVisibleFilterTags] = useState(0)
  const [totalFilterTags, setTotalFilterTags] = useState(0)
  const [openCustomizeReportDialog, setOpenCustomizeReportDialog] = useState(false)
  const [isReportDownloading, setIsReportDownloading] = useState(false)
  const [reportUrl, setReportUrl] = useState(false)
  const [customReporting, setCustomReporting] = useState("off")

  const currentHiddenTags = useRef(0)
  const itemsPerPage = 20
  const childOffset = 50

  const startItem = (page - 1) * itemsPerPage + 1
  const endItem = Math.min(page * itemsPerPage, totalEntries)

  useEffect(() => {
    loadConfigureFilters()
  }, [])

  useEffect(() => {
    fetchData(page, filters, filter)
    fetchFilters()
  }, [page])

  useEffect(() => {
    setTransactionDataSource(
      transactionDataRecords
        ? transactionDataRecords.map((transaction, index) => {
            transaction.index = startItem + index
            return transaction
          })
        : []
    )
    setTimeout(() => ReactTooltip.rebuild())
  }, [transactionDataRecords])

  useEffect(() => {
    async function enableCustomReportDownload() {
      const treatment = await SplitClient.getTreatment("CUSTOM_FMC_REPORT", currentUser)
      setCustomReporting(treatment["CUSTOM_FMC_REPORT"])
    }
    enableCustomReportDownload()
  }, [])

  const handleOpen = () => {
    setModalOpen(true)
  }

  const handleClose = () => {
    setModalOpen(false)
  }

  const openConfigureSideModal = () => {
    setOpenConfigureModal(true)
    setFilter(null)
    if (filter) {
      fetchData(page, {})
      setFilters({})
    }
  }

  const fetchData = async (query, filters, filter = null) => {
    const { isFMC, fleetId } = getFmcAndFleetId()

    setFilter(filter)
    setFilters(filters)
    await store.dispatch({
      type: "LOAD_TRANSACTIONS_REPORT",
      payload: {
        page: query,
        filters: { ...convertDateToString(filters), ...(isFMC ? {} : { fleet_id: [fleetId] }) },
        filter_key: filter && filter.filter_key,
        isFMC: isFMC
      },
      callback: afterLoadReportData(query, filters)
    })
    setOpenSavedHistoryModal(false)
  }

  const afterLoadReportData = (query, filters) => {
    fetchReportSummaryData(query, filters)
  }

  const fetchFilters = () => {
    const { isFMC, fleetId } = getFmcAndFleetId()

    store.dispatch({
      type: "LOAD_FILTERS_DATA",
      payload: {
        is_fmc: isFMC,
        ...(isFMC ? {} : { filters: { fleet_id: [fleetId] } })
      }
    })
  }

  const fetchReportSummaryData = async (query, filters) => {
    const { isFMC, fleetId } = getFmcAndFleetId()

    store.dispatch({
      type: "LOAD_REPORT_SUMMARY_DATA",
      payload: {
        page: query,
        filters: { ...convertDateToString(filters), ...(isFMC ? {} : { fleet_id: [fleetId] }) },
        isFMC: isFMC
      }
    })
  }

  const handleReportUrl = (url) => {
    setReportUrl(url)
  }

  const downloadReports = (selectedOptions) => {
    setIsReportDownloading(true)
    const downloadFilters = filter ? filter.filters : filters
    const { isFMC, fleetId } = getFmcAndFleetId()

    store.dispatch({
      type: "GENERATE_TRANSACTIONS_REPORT",
      payload: {
        filters: {
          ...convertDateToString(downloadFilters),
          ...(isFMC ? {} : { fleet_id: [fleetId] })
        },
        filter_key: filter && filter.filter_key,
        isFMC: isFMC,
        columns: selectedOptions
      },
      callback: (response, status) => {
        setIsReportDownloading(false)
        if (status === NOTIFICATION_TYPE.SUCCESS) {
          !isEmpty(response.file_path) && handleReportUrl(response.file_path.split("/").pop())
        }
      }
    })
  }

  //Remove this when bug is fixed in the backend
  const downloadReportsOld = () => {
    const downloadFilters = filter ? filter.filters : filters
    const { isFMC, fleetId } = getFmcAndFleetId()

    store.dispatch({
      type: "OLD_DOWNLOAD_TRANSACTIONS_REPORT",
      payload: {
        filters: {
          ...convertDateToString(downloadFilters),
          ...(isFMC ? {} : { fleet_id: [fleetId] })
        },
        filter_key: filter && filter.filter_key,
        isFMC: isFMC
      }
    })
  }

  const selectDownloadMethod = () => {
    if (customReporting === "on") {
      handleCustomizeReportDialog()
    } else downloadReportsOld()
  }

  const handleCustomizeReportDialog = () => {
    setOpenCustomizeReportDialog((prev) => !prev)
  }

  const loadConfigureFilters = () => {
    const { isFMC, fleetId } = getFmcAndFleetId()

    store.dispatch({
      type: "LOAD_CONFIGURE_REPORT_FILTERS",
      payload: {
        is_fmc: isFMC,
        ...(isFMC ? {} : { filters: { fleet_id: [fleetId] } })
      }
    })
  }

  const checkOverflow = () => {
    setVisibleFilterTags(0)
    const parent = document.querySelector(".clear-filter-link")
    const elements = document.querySelectorAll(".filter-tag-divider")

    let count = 0

    elements.forEach((element, key) => {
      if (key >= elements.length / 2) return
      if (!element) return
      if (!isVisible(parent, element, currentHiddenTags.current)) {
        count++
      }
    })

    setVisibleFilterTags(count)
    currentHiddenTags.current = count
  }

  function isVisible(parent, child, currentVisibleCount) {
    return parent && child && parent.offsetLeft > child.offsetLeft + childOffset
  }

  useEffect(() => {
    window.addEventListener("resize", checkOverflow)
    return () => window.removeEventListener("resize", checkOverflow)
  }, [])

  useEffect(() => {
    checkOverflow()
  }, [filters, filter])

  const options = [{ key: "1", text: t("transactionReportOptionsLabel"), value: t("shopOrder") }]

  const filterOptions = {
    selectableRows: "none",
    print: false,
    download: false,
    search: false,
    filter: false,
    viewColumns: false,
    fullWidth: true,
    page: page,
    serverSide: true,
    count: 1,
    rowsPerPage: itemsPerPage,
    pagination: true,
    customFooter: () => {
      return (
        <div className="custom-pagination">
          <Pagination
            defaultActivePage={1}
            totalPages={totalPages}
            activePage={page}
            firstItem={null}
            lastItem={null}
            prevItem={{ content: t("previousLabel"), icon: "arrow left" }}
            nextItem={{
              content: (
                <span>
                  {" "}
                  {t("nextLabel")}{" "}
                  <Icon name="arrow right" style={{ float: "right", marginLeft: "0.357em" }} />{" "}
                </span>
              )
            }}
            onPageChange={onChange}
            siblingRange={1}
            ellipsisItem={
              totalPages > 3 ? { content: <Icon name="ellipsis horizontal" />, icon: true } : null
            }
          />
          <div>{startItem && endItem ? `${startItem} - ${endItem} of ${totalEntries}` : ""}</div>
        </div>
      )
    },
    textLabels: {
      body: {
        noMatch: loadingTransactionRecords ? (
          <>
            <MiniLoader inverted={false} /> {t("loadingReportDataLabel")}
          </>
        ) : (
          <> {t("noMatchingData")} </>
        )
      }
    }
  }

  const onChange = (e, pageInfo) => {
    setTransactionDataSource([])
    setPage(pageInfo.activePage)
    window.scrollTo(0, 0)
  }

  const filterReportData = (filters, filter = null) => {
    setFilter(filter)
    setFilters(filters)
    fetchData(1, filters, filter)
    fetchReportSummaryData(1, filters)
    setOpenConfigureModal(false)
    checkOverflow()
    setTransactionDataSource([])
  }

  const clearFilters = () => {
    setFilters({})
    setTransactionDataSource([])
    fetchData(1, {})
    fetchReportSummaryData(1, {})
    setPage(1)
  }

  const reportEdit = (filter) => {
    setFilters(filter ? filter.filters : filters)
    setFilter(filter)
    setOpenSavedHistoryModal(false)
    setOpenConfigureModal(true)
  }

  const closeConfigureModal = (filter) => {
    setOpenConfigureModal(false)
    setFilter(filter)
  }

  const runReport = (filter) => {
    setTransactionDataSource([])
    fetchData(1, filter.filters, filter)
    setPage(1)
    setFilters(filter.filters)
    if (openConfigureModal) {
      setOpenConfigureModal(false)
      setOpenConfigureModal(true)
    }
    checkOverflow()
  }

  const removeFilter = (filterName) => {
    const currentFilter = Object.assign({}, filters)
    if (Array.isArray(currentFilter[filterName])) {
      currentFilter[filterName] = []
    } else {
      delete currentFilter[filterName]
    }

    if (filterName === "date") {
      currentFilter["appointment_datetime_from"] = null
      currentFilter["appointment_datetime_to"] = null
    } else if (filterName === "amount_string") {
      currentFilter["amount"] = null
      currentFilter["cost_filter_option"] = null
      currentFilter["price_greater_than"] = null
      currentFilter["price_less_than"] = null
    }

    if (filter && filter.filter_name) {
      const { isFMC, fleetId } = getFmcAndFleetId()

      store.dispatch({
        type: "CREATE_UPDATE_FILTER",
        payload: {
          filters: {
            ...convertDateToString(currentFilter),
            ...(isFMC ? {} : { fleet_id: [fleetId] })
          },
          isFMC: isFMC,
          t: t,
          reportEditEnabled: true,
          filter_key: filter.filter_key,
          filter_name: filter.filter_name
        },
        callback: (status, data) => {
          const { type, title, message, filter } = data
          if (status === SUCCESS) filterReportData(currentFilter, filter)
          return CustomNotification(type, title, message, t)
        }
      })
    } else {
      filterReportData(currentFilter)
    }
  }

  const appliedFilters = () => {
    const labels = {
      service_name: t("servicesLabel"),
      fleet_id: t("fleetsLabel"),
      company_id: t("shopsLabel"),
      year: t("yearsLabel"),
      make: t("makesLabel"),
      model: t("modelsLabel"),
      time_period: t("periodLabel"),
      date: t("dateLabel"),
      amount_string: t("totalLabel")
    }

    const formatDate = (from, to) => {
      if (from && to) {
        return `${moment(from).format(Date_Format.MM_DD_YY)} - ${moment(to).format(
          Date_Format.MM_DD_YY
        )}`
      } else if (from) {
        return `From ${moment(from).format(Date_Format.MM_DD_YY)}`
      } else if (to) {
        return `To ${moment(to).format(Date_Format.MM_DD_YY)}`
      }
      return ""
    }

    const formatAmount = (price_greater_than, price_less_than) => {
      if (price_greater_than == null && price_less_than == null) return null
      return (price_greater_than || price_less_than) && price_greater_than
        ? `${t("constFilterGreaterThanOptionLabel")} ${price_greater_than}`
        : `${t("constFilterLessThanOptionLabel")} ${price_less_than}`
    }

    const formatTags = (obj) => {
      obj.date =
        formatDate(obj.appointment_datetime_from, obj.appointment_datetime_to) || obj.date || ""
      obj.amount_string = formatAmount(obj.price_greater_than, obj.price_less_than)

      const filterKeysToExclude = [
        "updated_at",
        "viewed_at",
        "filter_name",
        "appointment_datetime_from",
        "appointment_datetime_to",
        "cost_filter_option",
        "price_greater_than",
        "price_less_than",
        "amount"
      ]

      const predefinedOrder = [
        "fleet_id",
        "service_name",
        "company_id",
        "year",
        "make",
        "model",
        "time_period",
        "date",
        "amount_string"
      ]

      const tags = Object.entries(obj)
        .filter(([key, value]) => value && value.length && !filterKeysToExclude.includes(key))
        .sort((a, b) => predefinedOrder.indexOf(a[0]) - predefinedOrder.indexOf(b[0]))

      if (tags && tags.length !== totalFilterTags) {
        setTotalFilterTags(tags.length)
      }

      return tags
        .slice(0, visibleFilterTags ? -visibleFilterTags : tags.length)
        .map(([key, value]) => {
          const label = labels[key]
          if (!label || !value) return null

          const values = Array.isArray(value) ? value : [value]
          return (
            <React.Fragment key={key}>
              <span className="filter-tag">
                <span className="filter-tag-label">{label}</span>:
                {values.slice(0, 2).map((item, index) => (
                  <span
                    key={index}
                    className={`${
                      label !== t("dateLabel") && label !== t("totalLabel") ? "filter-tag-item" : ""
                    }`}
                  >
                    {key === "fleet_id"
                      ? fleetsNameById[item]
                      : key === "company_id"
                      ? shopsNameById[item]
                      : item}
                  </span>
                ))}
                {values.length > 2 && <span key="more">+{values.length - 2}</span>}
                {tags.length > 1 && (
                  <ReactSVG
                    src={CrossIcon}
                    svgClassName="cross-icon"
                    onClick={() => removeFilter(key)}
                  />
                )}
              </span>
              <span className="filter-tag-divider"></span>
            </React.Fragment>
          )
        })
    }

    return (
      <>
        {formatTags(Object.assign({}, filters))}
        {visibleFilterTags !== 0 && totalFilterTags > visibleFilterTags && (
          <>
            <span className="filter-tag">+{visibleFilterTags}</span>
            <span className="filter-tag-divider"></span>
          </>
        )}
      </>
    )
  }

  return (
    <React.Fragment>
      <ApplicationLayout>
        <FMCOrderHeader header={t("reports:reportsLabel")} />
        <span />
        <div className="reporting-section">
          <div className="reporting-header">
            <div className="reporting-header-left-section">
              <div className="reporting-type-section">
                <span className="reporting-type-label">{t("reports:reportsTypelabel")}</span>
                <div className="reporting-type-select-section">
                  <Select
                    onChange={(event) => event.target.value && setReportingType(event.target.value)}
                    options={options}
                    placeholder={t("transactionPlaceholderLabel")}
                    value={reportingType}
                  />
                </div>
                <div className="configure-new-data-button">
                  <div className="label-cover" onClick={() => openConfigureSideModal()}>
                    <span className="label">{t("reports:configureNewDataLabel")}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="reporting-header-right-section">
              <div className="history-header-section">
                <span className="saved-history-label">{t("reports:savedHistoryLabel")}</span>
                <div
                  className={`all-history-link-section hand-icon ${
                    !reportFilters.length > 0 && "disabled-icon"
                  }`}
                  onClick={() => reportFilters.length > 0 && setOpenSavedHistoryModal(true)}
                >
                  <span className="label ">
                    {t("reports:allHistoryLabel")} ({reportFilters.length})
                  </span>
                </div>
              </div>
              <div className="saved-reports-name-section">
                <div className="saved-reports-total-section">
                  <div className="saved-reports-total-cover">
                    <span className="saved-reports-label">{t("reports:savedReportsLabel")}</span>
                  </div>
                </div>
                {loadingFilterRecords && <MiniLoader inverted={false} />}
                <div className="scrollable-container">
                  {reportFilters.map((reportFilter) => (
                    <span
                      className={`saved-reports-name hand-icon ${
                        filter &&
                        filter.filter_name === reportFilter.filter_name &&
                        "show-selected-report"
                      }`}
                      onClick={() => runReport(reportFilter)}
                    >
                      {reportFilter.filter_name}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="reporting-main-content">
            <div className="main-content">
              <ReactTooltip
                effect={"float"}
                type={"light"}
                id="services-tooltip"
                opacity={1.0}
                class={"opacity-1"}
                place={"top"}
                getContent={(e) => (
                  <ul>{e && e.split(",").map((item, index) => <li>{item}</li>)}</ul>
                )}
              />
              <ReactTooltip
                effect={"float"}
                type={"light"}
                id="default-tooltip"
                opacity={1.0}
                class={"opacity-1"}
                place={"top"}
                getContent={(e) => <p>{e}</p>}
              />
              <MUIDataTable
                title={
                  <>
                    {filter && filter.filter_name && (
                      <div className="current-report-name">
                        <span className="label">{t("reports:filterSavedAs")}</span>{" "}
                        {filter.filter_name}
                      </div>
                    )}
                    <div className="reporting-main-content-card-header">
                      <div className="card-header-left-section">
                        <div className="summary-data-section">
                          <div className="summary-data-label-cover">
                            <span className="label">{t("reports:totalSpentLabel")}</span>
                          </div>
                          <span className="summary-data-amount">
                            {reportSummaryData ? (
                              formatCurrency(reportSummaryData.totals || 0)
                            ) : (
                              <MiniLoader inverted={false} />
                            )}
                          </span>
                        </div>
                        <div className="summary-data-section">
                          <div className="summary-data-label-cover">
                            <span className="label">{t("reports:totalSavingLabel")}</span>
                            {/* <div className="show-up-percentage-section">
                            <div className="show-up-percentage-cover">
                              <div className="up-arrow-icon">
                                <ReactSVG src={UpArrow} />
                              </div>
                              <span className="label">10.0%</span>
                            </div>
                          </div> */}
                          </div>
                          <span className="summary-data-amount">
                            {reportSummaryData ? (
                              formatCurrency(reportSummaryData.savings || 0)
                            ) : (
                              <MiniLoader inverted={false} />
                            )}
                          </span>
                        </div>
                        <div className="summary-data-section">
                          <div className="summary-data-label-cover">
                            <span className="label">{t("reports:totalTransactionsLabel")}</span>
                          </div>
                          <span className="summary-data-amount">
                            {reportSummaryData ? totalEntries : <MiniLoader inverted={false} />}
                          </span>
                        </div>
                        <div className="summary-data-section">
                          <div className="summary-data-label-cover">
                            <span className="label">{t("reports:totalFilters")}</span>
                          </div>
                          <span className="summary-data-amount">
                            {reportSummaryData ? totalFilterTags : <MiniLoader inverted={false} />}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="reporting-secondary-header">
                      <div className="applied-filters">{appliedFilters()}</div>
                      <div>
                        <ReactTooltip
                          effect={"solid"}
                          type={"dark"}
                          id="action-item"
                          opacity={1.0}
                          class={"opacity-1"}
                          place={"bottom"}
                        />
                        <div className="card-header-right-section">
                          {!isEmpty(filters) && (
                            <div className="clear-filter-link" onClick={() => clearFilters()}>
                              {t("reports:clearFiltersLabel")}
                            </div>
                          )}
                          <div
                            className={`reports-icon hand-icon ${
                              transactionDataSource &&
                              transactionDataSource.length == 0 &&
                              "disabled-icon"
                            }`}
                            onClick={() =>
                              transactionDataSource &&
                              transactionDataSource.length > 0 &&
                              selectDownloadMethod()
                            }
                            data-tip={t("downloadReport")}
                            data-for={"action-item"}
                          >
                            <ReactSVG src={ReportDownload} />
                          </div>
                          <div
                            className={`reports-icon hand-icon ${
                              !(
                                (filter && Object.keys(filter).length > 0) ||
                                (filters && Object.keys(filters).length > 0)
                              ) && "disabled-icon"
                            }`}
                            data-tip={t("editFilter")}
                            data-for={"action-item"}
                          >
                            <ReactSVG
                              src={ReportFilter}
                              onClick={() =>
                                ((filter && Object.keys(filter).length > 0) ||
                                  (filters && Object.keys(filters).length > 0)) &&
                                reportEdit(filter)
                              }
                            />
                          </div>
                          <div
                            className={`reports-icon hand-icon ${
                              !(filters && Object.keys(filters).length > 0 && !filter) &&
                              "disabled-icon"
                            }`}
                            onClick={() =>
                              filters &&
                              Object.keys(filters).length > 0 &&
                              !filter &&
                              setModalOpen(true)
                            }
                            data-tip={t("saveFilter")}
                            data-for={"action-item"}
                          >
                            <ReactSVG src={ReportCopy} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                }
                data={transactionDataSource}
                columns={transactionReportColumnDefs()}
                options={filterOptions}
                className="custom-header"
              />
            </div>
          </div>
          {openConfigureModal ? (
            <ReportSideModal
              filterReportData={filterReportData}
              filtersData={filters}
              closeConfigureModal={() => setOpenConfigureModal(false)}
              filter={filter}
              handleClose={() => closeConfigureModal()}
            />
          ) : (
            <></>
          )}
          {openSavedHistoryModal ? (
            <ReportHistoryModal
              closeConfigureModal={() => setOpenSavedHistoryModal(false)}
              fetchData={fetchData}
              reportEdit={reportEdit}
              filters={reportFilters}
              loadingFilterRecords={loadingFilterRecords}
              clearFilters={clearFilters}
            />
          ) : (
            <></>
          )}
        </div>
        {openCustomizeReportDialog && (
          <CustomizeReportModal
            modalOpen={openCustomizeReportDialog}
            handleClose={handleCustomizeReportDialog}
            handleOpen={handleCustomizeReportDialog}
            handleDownload={(selectedColumns) => downloadReports(selectedColumns)}
            isReportDownloading={isReportDownloading}
            reportUrl={reportUrl}
            handleReportUrl={handleReportUrl}
          />
        )}
        {modalOpen && (
          <SaveReportModal
            modalOpen={modalOpen}
            handleClose={handleClose}
            handleOpen={handleOpen}
            filters={filters}
            setFilterData={(filter) => setFilter(filter)}
          />
        )}
      </ApplicationLayout>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  const { reports } = state
  return {
    currentUser: state.application.userSessionData || (state.users && state.users.currentUser),
    transactionDataRecords: reports.transactionReportData.orders,
    loadingTransactionRecords: reports.loadingTransactionRecords,
    totalPages: reports.transactionReportData.total_pages,
    totalEntries: reports.transactionReportData.total_reocrds,
    reportSummaryData: reports.reportSummaryData,
    reportFilters: reports.filters,
    loadingFilterRecords: reports.loadingFilterRecords,
    fleetsNameById:
      reports.configureFilters &&
      reports.configureFilters.fleets.reduce((acc, item) => {
        acc[item.id] = item.name
        return acc
      }, {}),
    shopsNameById:
      reports.configureFilters &&
      reports.configureFilters.companies.reduce((acc, item) => {
        acc[item.id] = item.name
        return acc
      }, {})
  }
}

export const CollapsableServiceTable = ({ data }) => {
  return (
    <ul className="collapsable-service-column" data-tip={data} data-for={"services-tooltip"}>
      {data.slice(0, 2).map((item, index) => (
        <li className="collapsable-service-row">{item}</li>
      ))}
      {data.length > 2 && `+ ${data.length - 2}`}
    </ul>
  )
}

export default connect(mapStateToProps, null)(FMCReporting)
