import React, { Component } from "react"
import { connect } from "react-redux"

import { navigationPaths } from "../../constants/paths"
import { getSubdomain } from "../../components/shared/StylesheetInjector"
import Alert from "../../components/shared/Alert"
import ForgotPasswordForm from "../../components/sessions/ForgotPasswordForm"
import NoSessionLayout from "../../layouts/NoSessionLayout"
import { withTranslation } from "react-i18next"
import { setupSplitFlags, ON } from "../../components/config/SplitClient"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"
let sub = getSubdomain()

class ForgotPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      alertMessage: "",
      alertType: "default",
      isSubmitting: false
    }
  }

  afterRequest(data) {
    this.setState({ isSubmitting: false })

    if (data.status === "success") {
      this.onRequestSuccess(data)
    } else {
      this.onRequestFailure(data)
    }
  }

  async componentDidMount() {
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())

    await setupSplitFlags.bind(this)({ sub_domain: sub })
  }

  onSubmit(formData) {
    this.setState({ isSubmitting: true })

    this.props.dispatch({
      type: "FORGOT_PASSWORD_SAGA",
      payload: { formData: formData, t: this.props.t },
      callback: this.afterRequest.bind(this)
    })
  }

  onRequestFailure(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  onRequestSuccess(data) {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: data.alertType
    })
  }

  render() {
    const { alertMessage, alertType, isSubmitting, isFleetCaptchaFlag } = this.state
    const { t, i18n } = this.props
    return (
      <NoSessionLayout page="forgotPasswordPage">
        <div className={"no-session-content"}>
          {alertMessage && <Alert message={alertMessage} type={alertType} />}
          <h1>{t("forgotPasswordLabel")}</h1>

          <ForgotPasswordForm
            isSubmitting={isSubmitting}
            onSubmit={this.onSubmit.bind(this)}
            language={i18n.language}
            isFleetCaptchaFlag={isFleetCaptchaFlag === ON}
            t={t}
          />
        </div>
      </NoSessionLayout>
    )
  }
} // class ForgotPassword
const mapStateToProps = (state) => ({
  currentUser: state.users.currentUser
})
export default connect(mapStateToProps)(withTranslation("common")(ForgotPassword))
