import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { navigationPaths } from "../../constants/paths"

import Alert from "../../components/shared/Alert"
import ApplicationLayout from "../../layouts/ApplicationLayout"
import LoadingError from "../../components/shared/LoadingError"
import LoadingThrobber from "../../components/shared/LoadingThrobber"
import MaintenanceHistoryForm from "../../components/maintenance_history/MaintenanceHistoryForm"
import { logFirebaseAnalyticsScreenName } from "../../helpers/googleAnalyticsHelpers"
import { getRouteNameFromUrl } from "../../helpers/segmentHelpers"

class MaintenanceHistoryNew extends Component {
  static propTypes = {
    isLoading: PropTypes.bool.isRequired,
    isLoadingError: PropTypes.bool.isRequired,
    shops: PropTypes.array.isRequired
  }

  state = {
    isSubmitting: false,
    alertMessage: "",
    alertType: ""
  }

  componentDidMount() {
    logFirebaseAnalyticsScreenName(getRouteNameFromUrl())

    const { shops, location, dispatch } = this.props
    const data = location.state && location.state.previousFormData
    const vehicle = (data || {}).vehicle
    let payload = { shops: shops }
    if (vehicle) payload.vehicle = vehicle

    dispatch({
      type: "SERVICE_REQUEST_LOAD_SAGA",
      payload: payload
    })
  }

  componentWillUnmount() {
    const { dispatch } = this.props
    dispatch({
      type: "RESET_SHOPS_FILTER_SAGA"
    })
  }

  afterFormSubmit = (status, data) => {
    this.setState({ isSubmitting: false })
    if (status === "success") {
      this.onFormSubmitSuccess(data)
    } else {
      this.onFormSubmitFailure(data)
    }
  }

  onFormSubmit = async (formData, maintenance_history_id) => {
    if (formData) {
      this.setState({ isSubmitting: true })
      await this.props.dispatch({
        type: "MAINTENANCE_HISTORY_CREATE_SAGA",
        payload: formData,
        maintenance_history_id: maintenance_history_id,
        callback: this.afterFormSubmit.bind(this)
      })
    }
  }

  onFormSubmitFailure = (data) => {
    this.setState({
      alertMessage: data.alertMessage,
      alertType: "error"
    })
  }

  onFormSubmitSuccess = (data) => {
    this.props.history.push({
      pathname: navigationPaths.servicesIndex(),
      state: {
        startingPage: "past",
        alertMessage: data.alertMessage,
        alertType: data.alertType
      }
    })
  }

  gotoShopSearch = (formData) => {
    this.props.history.push({
      pathname: navigationPaths.MaintenanceHistoryNew(),
      state: {
        previousFormData: formData
      }
    })
    this.props.history.push({
      pathname: navigationPaths.shopIndex(),
      state: {
        previousFormData: formData
      }
    })
  }

  shouldRenderContent() {
    const { isLoading, isLoadingError } = this.props

    return !isLoading && !isLoadingError
  }

  renderContent() {
    let {
      shops,
      currentUser: { language = "" },
      match
    } = this.props
    const data = this.props.location.state || {}
    // override shops to use data passed in if available
    if (data.shops && data.shops.length > 0) shops = data.shops
    if (data.shop) shops = [data.shop]
    const {
      customerComments,
      isSubmitting,
      selectedServices,
      shopId,
      vehicleId,
      shop,
      vehicle
    } = this.state

    if (this.shouldRenderContent()) {
      return (
        <MaintenanceHistoryForm
          isSubmitting={isSubmitting}
          onSubmit={this.onFormSubmit.bind(this)}
          previousFormData={
            (this.props.location.state && this.props.location.state.previousFormData) || {}
          }
          shops={shops}
          language={language}
          gotoShopSearch={this.gotoShopSearch}
          manualMaintenanceId={match.params.id}
        />
      )
    } else {
      return null
    }
  }

  render() {
    const { isLoading, isLoadingError } = this.props
    const { alertMessage, alertType } = this.state

    return (
      <ApplicationLayout>
        {!isLoading && alertMessage !== "" && <Alert message={alertMessage} type={alertType} />}

        <LoadingThrobber visible={isLoading} />

        <LoadingError visible={!isLoading && isLoadingError} />

        {this.renderContent()}
      </ApplicationLayout>
    )
  }
} // class MaintenanceHistoryNew

const mapStateToProps = (state) => ({
  isLoading: state.application.isLoading,
  isLoadingError: state.application.isLoadingError,
  shops: state.shops.shops,
  currentUser: state.users.currentUser || state.application.userSessionData
})

export default connect(mapStateToProps)(MaintenanceHistoryNew)
