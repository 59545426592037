import { call, put, takeEvery } from "redux-saga/effects"

import api from "../../utilities/api"
import * as apiErrorHelpers from "../../helpers/apiErrorHelpers"
import * as sessionHelpers from "../../helpers/sessionHelpers"
import { AUTHENTICATION_TOKEN } from "../../constants/application"

const PAYMENT_ACTIVITIES = "/payment_activities"
const SUBSCRIPTION_ACTIVITIES_URL = "/payment_activities/subscription_activities"
const SUBSCRIPTION_ACTIVITY_URL = "/payment_activities/subscription_activity"

export function* loadPaymentActivities(action) {
  try {
    const payload = action.payload || {}
    const payloadOptions = payload.options || {}

    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_PAYMENT_ACTIVITIES_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "PAYMENT_ACTIVITIES_LOAD_REQUESTED" })

    let body = {}
    if (payloadOptions.transactionsStartDate) {
      body.transactionsStartDate = payloadOptions.transactionsStartDate
      body.transactionsEndDate = payloadOptions.transactionsEndDate
      body.fleet_id = payloadOptions.fleet_id
    }

    const request = {
      url: PAYMENT_ACTIVITIES,
      body: body
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "PAYMENT_ACTIVITIES_LOAD_SUCCEEDED",
      payload: {
        paymentActivities: response.body
      }
    })

    if (action.callback) {
      action.callback()
    }
    ///////////////////////////

    ///////////////////////////
    //   LOAD CLIENT TOKEN   //
    ///////////////////////////

    ///////////////////////////

    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_ACTIVITIES_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_ACTIVITIES_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadPaymentActivities

export function* loadPaymentActivity(action) {
  try {
    const payload = action.payload || {}
    const payloadOptions = payload.options || {}

    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_PAYMENT_ACTIVITY_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "PAYMENT_ACTIVITY_LOAD_REQUESTED" })

    const request = {
      url: "/payment_activities/" + payloadOptions.payment_activity_id
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })

    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "PAYMENT_ACTIVITY_LOAD_SUCCEEDED",
      payload: {
        paymentActivity: response.body.payment_activity
      }
    })

    if (action.callback) {
      action.callback()
    }
    ///////////////////////////

    ///////////////////////////
    //   LOAD CLIENT TOKEN   //
    ///////////////////////////

    ///////////////////////////

    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_ACTIVITY_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_ACTIVITY_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadPaymentActivity

export function* loadSubscriptionActivities(action) {
  try {
    const payload = action.payload || {}
    const payloadOptions = payload.options || {}

    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_SUBSCIPTION_ACTIVITIES_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "PAYMENT_ACTIVITIES_LOAD_REQUESTED" })

    let body = {}
    if (payloadOptions.transactionsStartDate) {
      body.transactionsStartDate = payloadOptions.transactionsStartDate
      body.transactionsEndDate = payloadOptions.transactionsEndDate
      body.fleet_id = payloadOptions.fleet_id
    }

    const request = {
      url: SUBSCRIPTION_ACTIVITIES_URL,
      body: body
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })
    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "SUBSCRIPTION_ACTIVITIES_LOAD_SUCCEEDED",
      payload: {
        subscriptionActivities: response.body
      }
    })

    if (action.callback) {
      action.callback()
    }
    ///////////////////////////

    ///////////////////////////
    //   LOAD CLIENT TOKEN   //
    ///////////////////////////

    ///////////////////////////

    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_SUBSCIPTION_ACTIVITIES_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_ACTIVITIES_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadSubscriptionActivities

export function* loadSubscriptionActivity(action) {
  try {
    const payload = action.payload || {}
    const payloadOptions = payload.options || {}

    yield put({ type: "SET_LOADING" })
    yield put({ type: "SET_SUBSCIPTION_ACTIVITY_LOADING" })
    yield put({ type: "UNSET_LOADING_ERROR" })

    api.setAuthApi({
      Authorization: sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
    })

    yield put({ type: "PAYMENT_ACTIVITY_LOAD_REQUESTED" })

    let body = {}
    if (payloadOptions.subscription_id) {
      body.id = payloadOptions.subscription_id
    }

    const request = {
      url: SUBSCRIPTION_ACTIVITY_URL,
      body: body
    }

    const response = yield call(api.utility.get, api.path(request.url), { body: request.body })
    apiErrorHelpers.catchErrors(response.body)

    yield put({
      type: "SUBSCRIPTION_ACTIVITY_LOAD_SUCCEEDED",
      payload: {
        subscriptionActivity: response.body
      }
    })

    if (action.callback) {
      action.callback()
    }
    ///////////////////////////

    ///////////////////////////
    //   LOAD CLIENT TOKEN   //
    ///////////////////////////

    ///////////////////////////

    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_SUBSCIPTION_ACTIVITY_LOADING" })
  } catch (errors) {
    // eslint-disable-next-line
    errors = apiErrorHelpers.formatInternalErrors(errors)

    yield put({ type: "LOADING_FAILED" })
    yield put({ type: "SET_LOADING_ERROR" })
    yield put({ type: "UNSET_LOADING" })
    yield put({ type: "UNSET_PAYMENT_ACTIVITY_LOADING" })

    apiErrorHelpers.consoleLog(errors)
  }
} // function* loadSubscriptionActivity

export function* downloadPaymentActivities(action) {
  const payload = action.payload || {}
  const { transactionsStartDate, transactionsEndDate, fleet_id } = payload.options
  const authToken = sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)
  let url = ""
  url = `/payment_activities/download_payment_activities.csv?auth_token=${authToken}&transactionsStartDate=${transactionsStartDate}&transactionsEndDate=${transactionsEndDate}}&fleet_id=${fleet_id}`
  window.open(api.path(url))
}

export function* downloadSubscriptionActivities(action) {
  const payload = action.payload || {}
  const { subscriptionStartDate, subscriptionEndDate, fleet_id } = payload.options
  const authToken = sessionHelpers.getApplicationCookie(AUTHENTICATION_TOKEN)

  // Construct the query string using URLSearchParams
  const params = new URLSearchParams({
    auth_token: authToken,
    transactionsStartDate: subscriptionStartDate,
    transactionsEndDate: subscriptionEndDate,
    fleet_id: fleet_id
  })

  // Construct the full URL
  const url = `/payment_activities/subscription_activities/download_payment_activities.csv?${params.toString()}`

  // Open the constructed URL
  window.open(api.path(url))
}

export default function* paymentActivitySaga() {
  yield takeEvery("PAYMENT_ACTIVITIES_LOAD_SAGA", loadPaymentActivities)
  yield takeEvery("PAYMENT_ACTIVITY_LOAD_SAGA", loadPaymentActivity)
  yield takeEvery("SUBSCRIPTION_ACTIVITIES_LOAD_SAGA", loadSubscriptionActivities)
  yield takeEvery("SUBSCRIPTION_ACTIVITY_LOAD_SAGA", loadSubscriptionActivity)
  yield takeEvery("DOWNLOAD_PAYMENT_ACTIVITIES_SAGA", downloadPaymentActivities)
  yield takeEvery("DOWNLOAD_PAYMENT_SUBSCRIPTION_SAGA", downloadSubscriptionActivities)
}
